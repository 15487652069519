import axios from "axios";

// lead

export const getLeadById = async (id) => {
  try {
    const response = await axios.get(`/Lead/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getLeadByTicketId = async (ticketId) => {
  try {
    const response = await axios.get(`/Lead/ByTicket/${ticketId}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const createLead = async (body) => {
  try {
    const response = await axios.post("/Lead", body);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const updateLead = async (body) => {
  try {
    const response = await axios.put(`/Lead`, body);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const checkInLead = async (leadId) => {
  try {
    const response = await axios.put(`/Lead/${leadId}/CheckIn`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getLeads = async () => {
  try {
    const response = await axios.get(`/Leads`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const deleteLead = async (leadId) => {
  try {
    const response = await axios.delete(`/Lead/${leadId}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

// motorcycle

export const getMotorcycleById = async (id) => {
  try {
    const response = await axios.get(`/Motorcycle/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const createMotorcycle = async (body) => {
  try {
    const response = await axios.post("/Motorcycle", body);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const updateMotorcycle = async (body) => {
  try {
    const response = await axios.put(`/Motorcycle`, body);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};


export const getMotorcycles = async () => {
  try {
    const response = await axios.get(`/Motorcycles`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const getMotorcycleSchedule = async (id) => {
  try {
    const response = await axios.get(`/Motorcycle/${id}/Schedule`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getMotorcycleAvailability = async (id, fromDate, toDate) => {
  try {
    const response = await axios.get(`/Motorcycle/${id}/Availability?fromDate=${fromDate}&toDate=${toDate}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteMotorcycle = async (id) => {
  try {
    const response = await axios.delete(`/Motorcycle/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// test drive

export const getTestDriveById = async (id) => {
  try {
    const response = await axios.get(`/TestDrive/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const createTestDrive = async (body) => {
  try {
    const response = await axios.post("/TestDrive", body);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const updateTestDrive = async (body) => {
  try {
    const response = await axios.put(`/TestDrive`, body);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};


export const getTestDrives = async (leadId) => {
  try {
    const response = await axios.get(`/TestDrives?leadId=${leadId}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const countTestDrives = async (leadId) => {
  try {
    const response = await axios.get(`/TestDrives/Count?leadId=${leadId}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const deleteTestDrive = async (id) => {
  try {
    const response = await axios.delete(`/TestDrive/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// exhibitor

export const getExhibitorById = async (id) => {
  try {
    const response = await axios.get(`/Exhibitor/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getAllExhibitors = async () => {
  try {
    const response = await axios.get(`/Exhibitors`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const createExhibitor = async (body) => {
  try {
    const response = await axios.post("/Exhibitor", body);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const updateExhibitor = async (body, updatePassword) => {
  try {
    const response = await axios.put(`/Exhibitor?updatePassword=${updatePassword}`, body);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const isUserLoggedIn = async (id) => {
  try {
    const response = await axios.get(`/Exhibitor/${id}`);
    return true;
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      return false;
    }
    return error.response.data;
  }
};