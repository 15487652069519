import React, { useEffect, useState } from 'react'
import Navbar from './components/Navbar'
import Banner from './components/Banner'
import { useNavigate, useParams } from 'react-router-dom'
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";
import errorImg from "../images/error-o.svg"
import { getLeadById, getMotorcycleById, getMotorcycleSchedule, getTestDriveById, updateTestDrive } from '../common/ApiService';
import { api, getTicketById } from 'ticketino-api-client';
import axios from 'axios';

const defaultTestDrive = {
    id: 0,
    leadId: 0,
    exhibitorId: 0,
    motorcycleId: 0,
    scanner: "",
    name: "",
    remarks: "",
    checkInDate: null,
    checkOutDate: null
}

const TestDrive = () => {

    const [testDrive, setTestDrive] = useState({ ...defaultTestDrive });
    const [existingSchedule, setExistingSchedule] = useState([]);

    const [error, setError] = useState("");

    const { testDriveId } = useParams();

    useEffect(() => {
        if (testDriveId > 0) {
            loadTestDrive(testDriveId);
        }
    }, [testDriveId])

    let navigate = useNavigate();

    const loadToken = async () => {
        await axios.get("Ticketino/Token").then(async (res) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;
            api.defaults.headers.common['Authorization'] = "Bearer " + res.data;
        }).catch(() => {
        })
    }

    const loadTestDrive = async (id) => {
        try {
            let updatedTestDrive = await getTestDriveById(id)

            const checkInDate = updatedTestDrive.checkInDate ? new Date(updatedTestDrive.checkInDate) : null;
            const checkOutDate = updatedTestDrive.checkOutDate ? new Date(updatedTestDrive.checkOutDate) : null;

            updatedTestDrive.checkInDate = checkInDate ?? null;
            updatedTestDrive.checkOutDate = checkOutDate ?? null;

            var lead = await getLeadById(updatedTestDrive.leadId);

            await loadToken();
            const ticket = await getTicketById(lead.ticketId)

            lead.address = { ...ticket };

            const motorcycle = await getMotorcycleById(updatedTestDrive.motorcycleId);
            const motorcycleSchedule = await getMotorcycleSchedule(updatedTestDrive.motorcycleId);

            updatedTestDrive.lead = lead;
            updatedTestDrive.motorcycle = motorcycle;

            setTestDrive(updatedTestDrive);
            setExistingSchedule(motorcycleSchedule);
        } catch (error) {
            console.error(error);
        }
    }

    const onSubmit = async () => {
        const response = await updateTestDrive({ ...testDrive });

        if (response?.id > 0) {
            navigate("/account");
        } else {
            setError(response);
        }
    }

    const isDateDisabled = (date) => {
        return existingSchedule?.filter(es => es.testDriveId != testDrive?.id).some(({ checkIn, checkOut }) => {
            const checkinDate = new Date(checkIn);
            const checkoutDate = checkOut ? new Date(checkOut) : null;

            if (checkoutDate == null) {
                return date >= checkinDate
            } else {
                return (date >= checkinDate && date <= checkoutDate);
            }
        })
    }

    return (
        <>
            <Navbar />
            <Banner />
            <div className='page-container container mt-4 mb-5'>
                <>
                    <h1 className='text text-dark fw-semibold fs-3 m-3'>Testfahrt bearbeiten</h1>
                    <div className='content m-3'>
                        <div className="form-floating mb-3">
                            <input className="form-control" id="floatingInput" placeholder='Testfahrer' name='Testfahrer' value={testDrive?.lead?.address?.firstname + " " + testDrive?.lead?.address?.lastname} readOnly />
                            <label htmlFor="floatingInput">Testfahrer</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input className="form-control" id="floatingInput" placeholder='Motorrad' name='Motorrad' value={testDrive?.motorcycle?.marke + " " + testDrive?.motorcycle?.model + " - " + testDrive?.motorcycle?.licensePlate} readOnly />
                            <label htmlFor="floatingInput">Motorrad</label>
                        </div>
                        <div className='col-md-6 col-12 mb-3 row'>
                            <label className="form-label text text-secondary fs-6">
                                Start Probefahrt (Check-In)<span className='text text-danger'>*</span>
                            </label>
                            <div className="col-12 mb-1">
                                <DatePicker
                                    dateFormat="dd.MM.yy, HH:mm"
                                    className="form-control datepicker-input"
                                    selected={testDrive.checkInDate}
                                    onChange={(date) => setTestDrive({ ...testDrive, checkInDate: date })}
                                    autoComplete='off'
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    filterDate={(date) => !isDateDisabled(date)}
                                    filterTime={(time) => !isDateDisabled(time)}
                                    showTimeSelect />
                            </div>
                        </div>
                        <div className='col-md-6 col-12 mb-3 row'>
                            <label className="form-label text text-secondary fs-6">
                                Ende Probefahrt (Check-Out)<span className='text text-danger'>*</span>
                            </label>
                            <div className="col-12 mb-1">
                                <DatePicker
                                    dateFormat="dd.MM.yy, HH:mm"
                                    className="form-control datepicker-input"
                                    selected={testDrive.checkOutDate}
                                    onChange={(date) => setTestDrive({ ...testDrive, checkOutDate: date })}
                                    autoComplete='off'
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    filterDate={(date) => !isDateDisabled(date)}
                                    filterTime={(time) => !isDateDisabled(time)}
                                    showTimeSelect />
                            </div>
                            <span className='text text-secondary fs-6' style={{ textDecoration: "underline" }} onClick={(date) => setTestDrive({ ...testDrive, checkOutDate: new Date() })}>Jetzt</span>
                        </div>
                        {error != "" && <div className="alert alert-danger" role="alert">
                            {error}
                        </div>}
                        <div className='row buttons-div'>
                            <div className="col-6 text-start">
                                <button className='button' onClick={() => navigate(-1)}>Zurück</button>
                            </div>
                            <div className="col-6 text-end">
                                <button className='button' onClick={onSubmit}>Speichern</button>
                            </div>
                        </div>
                    </div>
                </>
            </div>
        </>
    )
}

export default TestDrive