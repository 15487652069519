import "bootstrap/dist/css/bootstrap.css";
import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { ContextProvider } from "./views/session/UseStateContext";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
    <ContextProvider>
        <App />
    </ContextProvider>
);