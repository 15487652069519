import React from 'react'
import "../../style.css";

import banner from "../../images/banner.png"
import flyer from "../../images/logo.jpg"

const Banner = () => {
    return (
        <div className='container'>
            <div className='row m-0 pt-4'>
                <div>
                    <img className='text-center flyer' src={flyer}></img>
                </div>
            </div>
        </div>
    )
}

export default Banner