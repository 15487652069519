import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import Navbar from './components/Navbar';
import QRScanner from './components/QRScanner';
import Banner from './components/Banner';

const Scan = () => {

    const [code, setCode] = useState();
    let navigate = useNavigate();

    const onSubmit = () => {
        let updatedString = code?.replace(/[ .]/g, "")?.trim();
        navigate(`/contact/${updatedString}`)
    }

    return (
        <>
            <Navbar />
            <Banner />
            <div className='page-container container mt-4 mb-5'>
                <div className='content m-3'>
                    <h3 className='text text-dark fw-semibold fs-3 mb-3'>Bereit zum Scannen</h3>
                    <p className='text text-secondary fs-6'>
                        Scannen Sie den QR-Code auf dem Namensschild des Kunden, indem Sie auf den untenstehenden Button tippen.
                    </p>

                    <div className='mb-3'>
                        <QRScanner />
                    </div>

                    <p className='text text-secondary fs-6'>
                        Wenn der Scan auf Ihrem Gerät nicht funktioniert, können Sie die Ticketnummer eingeben, welche oberhalb des QR-Codes auf dem Namensschild des Kunden abgedruckt ist.
                    </p>

                    <div className="input-group mb-3">
                        <input className="form-control continue-input" placeholder="Ticketnummer eingeben" aria-label="Recipient's username" aria-describedby="basic-addon2" onChange={(e) => setCode(e.currentTarget.value)} value={code} />
                        <div className="input-group-append">
                            <button className='btn continue-btn ps-4 pe-4' onClick={onSubmit}>Senden</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Scan