import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { api } from 'ticketino-api-client';
import DatePicker from 'react-datepicker';

import Navbar from './components/Navbar';
import Banner from './components/Banner';
import Loading from './components/Loading';

import "react-datepicker/dist/react-datepicker.css";

const Guest = () => {
    const [ticketAddress, setTicketAddress] = useState({
        salutation: "",
        firstname: "",
        name: "",
        street: "",
        country: "",
        countryId: 176,
        postCode: "",
        city: "",
        mobile: "",
        email: "",
        confirmemail: "",
        ticketTypeId: "",
        customValue1: "",
        customValue2: "",
        customValue3: null,
        birthDate: new Date()
    })

    const [loading, setLoading] = useState(true);

    const { orderId } = useParams();

    let baseUrl = process.env.REACT_APP_BASEURL_API;

    let navigate = useNavigate();

    useEffect(() => {
        if (orderId > 0) {
            loadAddress(orderId);
        }
    }, [orderId])

    const loadAddress = async (orderId) => {
        try {
            await loadToken();
            const order = await getOrderByOrderId(orderId);
            const ticket = order.tickets[0];

            setTicketAddress({ ...ticketAddress, ...ticket, name: ticket.lastname });
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    }

    const loadToken = async () => {
        await axios.get("Ticketino/Token").then(async (res) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;
            api.defaults.headers.common['Authorization'] = "Bearer " + res.data;
        }).catch((error) => {
            console.error(error);
        })
    }

    const updateTicketAddress = async (ticketId) => {
        let updateTicketAddressBody = { ...ticketAddress };
        updateTicketAddressBody.birthDate = formatDate(ticketAddress.birthDate);

        try {
            const response = await axios.put(`${baseUrl}/Ticket/${ticketId}/Address`, updateTicketAddressBody);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    const updateBuyerAddress = async () => {
        let addAddressToBasketBody = { ...ticketAddress };
        addAddressToBasketBody.firstName = ticketAddress.firstname;
        addAddressToBasketBody.name = ticketAddress.name;
        addAddressToBasketBody.telephone = ticketAddress.mobile;
        addAddressToBasketBody.code1 = ticketAddress.customValue1;
        addAddressToBasketBody.code2 = ticketAddress.customValue2;
        addAddressToBasketBody.code4 = ticketAddress.customValue3;
        addAddressToBasketBody.code3 = formatDate(ticketAddress.birthDate);

        try {
            const response = await axios.put(`${baseUrl}/Order/${orderId}/Address`, addAddressToBasketBody);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    const getOrderByOrderId = async (orderId) => {
        try {
            const res = await axios.get(`${baseUrl}/Order/${orderId}`);
            return res.data;
        }
        catch (error) {
            console.error(error);
        }
    }

    const onInputChange = (e) => {
        setTicketAddress({ ...ticketAddress, [e.target.name]: e.target.value })
    }

    const formatDate = (date) => {
        const formattedDate = new Date(date);
        const year = formattedDate.getFullYear();
        const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
        const day = String(formattedDate.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    const onUpdate = async () => {
        await updateTicketAddress(ticketAddress.id)
        await updateBuyerAddress();
        navigate("/account")
    }

    return (
        <>
            <Navbar />
            <Banner />
            {loading ? <Loading /> :
                <div className='page-container container mt-4 mb-5'>
                    <>
                        <h1 className='text text-dark fw-semibold fs-3 m-3'>Töff Gast bearbeiten</h1>
                        <div className='content m-3'>
                            <div className="form-floating mb-3">
                                <input className="form-control" id="floatingInput" placeholder='Vorname' name='firstName' value={ticketAddress.firstname} onChange={onInputChange} />
                                <label htmlFor="floatingInput">Vorname</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input className="form-control" id="floatingInput" placeholder='Nachname' name='name' value={ticketAddress.name} onChange={onInputChange} />
                                <label htmlFor="floatingInput">Nachname</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input className="form-control" id="floatingInput" placeholder='Telefon' name='telephone' value={ticketAddress.mobile} onChange={onInputChange} />
                                <label htmlFor="floatingInput">Telefon</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input className="form-control" id="floatingInput" placeholder='Email' name='email' value={ticketAddress.email} onChange={onInputChange} />
                                <label htmlFor="floatingInput">Email</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input className="form-control" id="floatingInput" placeholder='Strasse' name='street' value={ticketAddress.street} onChange={onInputChange} />
                                <label htmlFor="floatingInput">Strasse</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input className="form-control" id="floatingInput" placeholder='PLZ' name='postCode' value={ticketAddress.postCode} onChange={onInputChange} />
                                <label htmlFor="floatingInput">PLZ</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input className="form-control" id="floatingInput" placeholder='Stadt' name='city' value={ticketAddress.city} onChange={onInputChange} />
                                <label htmlFor="floatingInput">Stadt</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input className="form-control" id="floatingInput" placeholder='Kanton' name='code1' value={ticketAddress.customValue1} onChange={onInputChange} />
                                <label htmlFor="floatingInput">Kanton</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input className="form-control" id="floatingInput" placeholder='Führerausweis Nr.' name='code2' value={ticketAddress.customValue2} onChange={onInputChange} />
                                <label htmlFor="floatingInput">Führerausweis Nr.</label>
                            </div>
                            <div className='row buttons-div'>
                                <div className="col-6 text-start">
                                    <button className='button' onClick={() => navigate(-1)}>Zurück</button>
                                </div>
                                <div className="col-6 text-end">
                                    <button className='button' onClick={onUpdate}>Speichern</button>
                                </div>
                            </div>
                        </div>
                    </>
                </div>}
        </>
    )
}

export default Guest