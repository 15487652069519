import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import Navbar from './components/Navbar'
import Banner from './components/Banner'
import Loading from './components/Loading'

import UseStateContext from './session/UseStateContext';
import { createMotorcycle, getAllExhibitors, getMotorcycleById, updateMotorcycle } from '../common/ApiService';

const defaultMotorcycle = {
    id: 0,
    handler: "",
    marke: "",
    model: "",
    internalNumber: "",
    licensePlate: "",
    status: 1,
    kiloMeterStart: "",
    exhibitorId: 0,
    standNumber: ""
}

const Motorcycle = () => {
    const [motorcycle, setMotorcycle] = useState({ ...defaultMotorcycle });

    const [exhibitors, setExhibitors] = useState([]);

    const [loading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(true);

    const [error, setError] = useState("");

    const { motorcycleId } = useParams();
    const { context: { exhibitor } } = UseStateContext();

    let navigate = useNavigate();

    useEffect(() => {
        if (motorcycleId > 0) {
            loadMotorcycle(motorcycleId);
        } else {
            setEditMode(false);
        }
    }, [motorcycleId])

    useEffect(() => {
        if (exhibitor?.isAdmin) {
            loadExhibitors();
        } else {
            setMotorcycle({ ...motorcycle, exhibitorId: exhibitor.id, standNumber: exhibitor.standNumber });
        }
    }, [exhibitor])

    const loadMotorcycle = async (id) => {
        try {
            const updatedMotorcycle = await getMotorcycleById(id);

            if (updatedMotorcycle.id > 0) {
                setMotorcycle({ ...updatedMotorcycle });
            } else {
                setEditMode(false);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const loadExhibitors = async () => {
        try {
            setLoading(true);

            const updatedExhibitors = await getAllExhibitors();
            setExhibitors(updatedExhibitors);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    }

    const onSubmit = async () => {
        const reponse = editMode ? await updateMotorcycle(motorcycle) : await createMotorcycle(motorcycle);

        if (reponse.id > 0) {
            navigate("/account");
        } else {
            setError(reponse);
        }
    }

    const onInputChange = (e) => {
        setMotorcycle({ ...motorcycle, [e.target.name]: e.target.value });
    };

    return (
        <>
            <Navbar />
            <Banner />
            {loading ? <Loading /> :
                <div className='page-container container mt-4 mb-5'>
                    <>
                        <h1 className='text text-dark fw-semibold fs-3 m-3'>Motorrad bearbeiten</h1>
                        <div className='content m-3'>
                            <div className="form-floating mb-3">
                                <select className="form-select" id="floatingSelect" name='exhibitorId' onChange={onInputChange} value={motorcycle.exhibitorId} disabled={!exhibitor.isAdmin}>
                                    {!(motorcycle.exhibitorId > 0) && <option value={0}></option>}
                                    {exhibitor.isAdmin ? exhibitors.map((exhibitor, index) => (
                                        <option key={index} value={exhibitor.id}>{exhibitor.name}</option>
                                    )) : <option value={exhibitor.id}>{exhibitor.name}</option>}
                                </select>
                                <label htmlFor="floatingSelect">Aussteller</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input className="form-control" id="floatingInput" placeholder='Marke' name='marke' value={motorcycle.marke} onChange={onInputChange} />
                                <label htmlFor="floatingInput">Marke</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input className="form-control" id="floatingInput" placeholder='Model' name='model' value={motorcycle.model} onChange={onInputChange} />
                                <label htmlFor="floatingInput">Model</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input className="form-control" id="floatingInput" placeholder='Interne Nummer' name='internalNumber' value={motorcycle.internalNumber} onChange={onInputChange} />
                                <label htmlFor="floatingInput">Interne Nummer</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input className="form-control" id="floatingInput" placeholder='Strassenkennzeichen' name='licensePlate' value={motorcycle.licensePlate} onChange={onInputChange} />
                                <label htmlFor="floatingInput">Strassenkennzeichen</label>
                            </div>
                            <div className="form-floating mb-3">
                                <select className="form-select" id="floatingSelect" name='status' onChange={onInputChange} value={motorcycle.status}>
                                    <option defaultValue={1} value={1}>Frei</option>
                                    <option value={2}>Besetzt</option>
                                    <option value={3}>Defekt</option>
                                </select>
                                <label htmlFor="floatingSelect">Status</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input className="form-control" id="floatingInput" placeholder='KM-Stand' name='kiloMeterStart' value={motorcycle.kiloMeterStart} onChange={onInputChange} />
                                <label htmlFor="floatingInput">KM-Stand</label>
                            </div>
                            {error != "" && <div className="alert alert-danger" role="alert">
                                {error}
                            </div>}
                            <div className='row buttons-div'>
                                <div className="col-6 text-start">
                                    <button className='button' onClick={() => navigate(-1)}>Zurück</button>
                                </div>
                                <div className="col-6 text-end">
                                    <button className='button' onClick={onSubmit}>Speichern</button>
                                </div>
                            </div>
                        </div>
                    </>
                </div>}
        </>
    )
}

export default Motorcycle