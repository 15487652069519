import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import AccessDenied from "./views/components/AccessDenied";
import PageNotFound from "./views/components/PageNotFound";

// session manager
import UseStateContext from "./views/session/UseStateContext";

// pages
import Login from "./views/Step10_Login";
import Scan from "./views/Step20_Scan";
import Contact from "./views/Step30_Contact";
import Success from "./views/Step40_Success";
import Account from "./views/Step05_Account";

import Lead from "./views/Step05_Lead";
import Motorcycle from "./views/Step05_Motorcycle";
import Guest from "./views/Step05_Guest";

import { isUserLoggedIn } from "./common/ApiService";
import Exhibitor from "./views/Step05_Exhibitor";
import TestDrive from "./views/Step05_TestDrive";

function App() {

    const { context, resetContext } = UseStateContext();

    // check if the user is logged in
    const { loggedIn, exhibitor, lead, leadId, motorcycleId, uniqueKey } = context;

    useEffect(() => {

        if (loggedIn) {
            checkLogin(exhibitor.id)
        }

    }, [])

    const checkLogin = async (id) => {
        const isLoggedIn = await isUserLoggedIn(id);

        if (!isLoggedIn) {
            resetContext();
        }
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={loggedIn ? <Scan /> : <Navigate to="/login" replace />} />
                <Route path="/contact/:uniqueKey" element={(loggedIn) ? <Contact /> : <Navigate to="/login" replace />} />
                <Route path="/success" element={loggedIn ? <Success /> : <Navigate to="/login" replace />} />

                <Route path="/denied" element={loggedIn ? <AccessDenied /> : <Navigate to="/login" replace />} />
                <Route path="/account" element={loggedIn ? <Account /> : <Navigate to="/login" replace />} />

                <Route path="/lead/:leadId" element={loggedIn ? <Lead /> : <Navigate to="/login" replace />} />
                <Route path="/guest/:orderId" element={loggedIn ? <Guest /> : <Navigate to="/login" replace />} />

                
                <Route path="/motorcycle" element={loggedIn ? <Motorcycle /> : <Navigate to="/login" replace />} />
                <Route path="/motorcycle/:motorcycleId" element={loggedIn ? <Motorcycle /> : <Navigate to="/login" replace />} />

                <Route path="/exhibitor" element={loggedIn ? <Exhibitor /> : <Navigate to="/login" replace />} />
                <Route path="/exhibitor/:exhibitorId" element={loggedIn ? <Exhibitor /> : <Navigate to="/login" replace />} />

                <Route path="/testdrive/:testDriveId" element={loggedIn ? <TestDrive /> : <Navigate to="/login" replace />} />

                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;