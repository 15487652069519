import React from 'react'
import Navbar from './components/Navbar';
import Banner from './components/Banner';

import image from "../images/check-circle-1.svg"
import { useNavigate } from 'react-router-dom';
import UseStateContext from './session/UseStateContext';

const Success = () => {

    const { context: { editMode }, setContext } = UseStateContext();

    let navigate = useNavigate();

    const onSubmit = () => {
        setContext({ lead: {}, leadId: 0 })
        navigate("/")
    }

    return (
        <>
            <Navbar />
            <Banner />
            <div className='page-container container mt-4'>
                <div className='content m-3'>
                    <div className='container text-center'>
                        <img className='success-img mb-3' src={image}></img>
                    </div>
                    <h3 className='text text-dark text-center fw-semibold fs-3 mb-3'>Testfahrt erfolgreich {editMode ? "aktualisiert" : "erfasst"}</h3>
                </div>
                <div className='container text-center'>
                    <button className='success-button' onClick={onSubmit}>Neue Testfahrt erfassen</button>
                </div>
            </div>
        </>
    )
}

export default Success