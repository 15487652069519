import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import DatePicker from 'react-datepicker';

import Navbar from './components/Navbar'
import Banner from './components/Banner'
import Loading from './components/Loading'

import "react-datepicker/dist/react-datepicker.css";

import UseStateContext from './session/UseStateContext';
import { getLeadById, getMotorcycleAvailability, getMotorcycleById, getMotorcycleSchedule, getMotorcycles, updateLead } from '../common/ApiService';
import { api, getTicketById } from 'ticketino-api-client';

const Lead = () => {
    const [details, setDetails] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const { context, setContext } = UseStateContext();
    const { leadId } = useParams();

    let baseUrl = process.env.REACT_APP_BASEURL_API;

    let navigate = useNavigate();

    useEffect(() => {
        loadLead(leadId);
    }, [leadId])

    const loadToken = async () => {
        await axios.get("Ticketino/Token").then(async (res) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;
            api.defaults.headers.common['Authorization'] = "Bearer " + res.data;
        }).catch(() => {
            setError(true);
            setLoading(false);
        })
    }

    const loadLead = async (id) => {
        try {
            let updatedLead = await getLeadById(id);

            // load the bearer token to authenticate the api request
            await loadToken();
            const ticket = await getTicketById(updatedLead.ticketId);

            setDetails({ ...updatedLead, address: {...ticket, name: ticket?.lastname} });
        } catch (error) {
            console.error(error);
        }
    }

    const updateBuyerAddress = async () => {
        let addAddressToBasketBody = { ...details?.address };
        addAddressToBasketBody.firstName = details?.address?.firstname;
        addAddressToBasketBody.name = details?.address?.name;
        addAddressToBasketBody.telephone = details?.address?.mobile;
        addAddressToBasketBody.code1 = details?.address?.customValue1;
        addAddressToBasketBody.code2 = details?.address?.customValue2;
        addAddressToBasketBody.code4 = details?.address?.customValue3;
        addAddressToBasketBody.code3 = formatDate(details?.address?.birthDate);

        try {
            const response = await axios.put(`${baseUrl}/Order/${details.orderId}/Address`, addAddressToBasketBody);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    const updateTicketAddress = async (ticketId) => {
        let updateTicketAddressBody = { ...details.address };
        updateTicketAddressBody.birthDate = formatDate(details?.address?.birthDate);

        try {
            const response = await axios.put(`${baseUrl}/Ticket/${ticketId}/Address`, updateTicketAddressBody);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    const formatDate = (date) => {
        const formattedDate = new Date(date);
        const year = formattedDate.getFullYear();
        const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
        const day = String(formattedDate.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    const onUpdate = async () => {

        let updateLeadBody = { ...details };

        const response = await updateLead(updateLeadBody);

        // simultaneously update the order address
        await updateTicketAddress(updateLeadBody.ticketId);
        await updateBuyerAddress();

        if (response.id > 0) {
            setContext({ lead: response });
            navigate("/account");
        } else {
            setError(response);
        }
    }

    const onInputChange = (e) => {
        setDetails({ ...details, [e.target.name]: e.target.value });
    };

    const onInputAddressChange = (e) => {
        setDetails((details) => {
            let updatedDetails = { ...details };
            updatedDetails.address[e.target.name] = e.target.value;
            return { ...updatedDetails };
        })
    }

    return (
        <>
            <Navbar />
            <Banner />
            {loading ? <Loading /> :
                <div className='page-container container mt-4 mb-5'>
                    <>
                        <h1 className='text text-dark fw-semibold fs-3 m-3'>Töff Details bearbeiten</h1>
                        <div className='content m-3'>
                            <div className="form-floating mb-3">
                                <input className="form-control" id="floatingInput" placeholder='Vorname' name='firstname' value={details?.address?.firstname} onChange={onInputAddressChange} />
                                <label htmlFor="floatingInput">Vorname</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input className="form-control" id="floatingInput" placeholder='Nachname' name='name' value={details?.address?.name} onChange={onInputAddressChange} />
                                <label htmlFor="floatingInput">Nachname</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input className="form-control" id="floatingInput" placeholder='Firma' name='company' value={details?.address?.company} onChange={onInputAddressChange} />
                                <label htmlFor="floatingInput">Firma</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input className="form-control" id="floatingInput" placeholder='Telefon' name='mobile' value={details?.address?.mobile} onChange={onInputAddressChange} />
                                <label htmlFor="floatingInput">Telefon</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input className="form-control" id="floatingInput" placeholder='Email' name='email' value={details?.address?.email} onChange={onInputAddressChange} />
                                <label htmlFor="floatingInput">Email</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input className="form-control" id="floatingInput" placeholder='Strasse' name='street' value={details?.address?.street} onChange={onInputAddressChange} />
                                <label htmlFor="floatingInput">Strasse</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input className="form-control" id="floatingInput" placeholder='PLZ' name='postCode' value={details?.address?.postCode} onChange={onInputAddressChange} />
                                <label htmlFor="floatingInput">PLZ</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input className="form-control" id="floatingInput" placeholder='Stadt' name='city' value={details?.address?.city} onChange={onInputAddressChange} />
                                <label htmlFor="floatingInput">Stadt</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input className="form-control" id="floatingInput" placeholder='Kanton' name='customValue1' value={details?.address?.customValue1} onChange={onInputAddressChange} />
                                <label htmlFor="floatingInput">Kanton</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input className="form-control" id="floatingInput" placeholder='Führerausweis Nr.' name='customValue2' value={details?.address?.customValue2} onChange={onInputAddressChange} />
                                <label htmlFor="floatingInput">Führerausweis Nr.</label>
                            </div>
                            <div className="form-floating mb-3">
                                <textarea className="form-control" id="floatingTextarea" placeholder="Bemerkung" name="remarks" value={details.remarks} onChange={onInputChange}></textarea>
                                <label htmlFor="floatingTextarea">Bemerkung</label>
                            </div>
                            {error != "" && <div className="alert alert-danger" role="alert">
                                {error}
                            </div>}
                            <div className='row buttons-div'>
                                <div className="col-6 text-start">
                                    <button className='button' onClick={() => navigate(-1)}>Zurück</button>
                                </div>
                                <div className="col-6 text-end">
                                    <button className='button' onClick={onUpdate}>Speichern</button>
                                </div>
                            </div>
                        </div>
                    </>
                </div>}
        </>
    )
}

export default Lead