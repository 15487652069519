import React from 'react'

import successImg from "../../images/check-circle-1.svg"

const Success = (props) => {

    const { lead, testDrive } = props;

    return (
        <div className='content m-3 mb-4'>
            <div className='row'>
                <div className='col-2 success-contact'><img src={successImg}></img></div>
                <div className='col-10 p-0 ps-2 success-content'>
                    <h1 className='text text-dark fw-bold fs-5 mb-1'>Scan erfolgreich</h1>
                    <p className='text text-dark fw-semibold fs-6 mb-0'>{lead?.firstName} {lead?.name}</p>
                    <p className='text text-secondary fs-6 mb-0'>{lead?.email}</p>
                    <p className={`text fs-6 mb-0 text-${lead?.numberOfTestDrives >= 7 ? "danger" : "success"}`}>{testDrive?.name?.length > 0 ? testDrive?.name : `Testfahrt ${lead?.numberOfTestDrives + 1}`}</p>
                </div>
            </div>
        </div>
    )
}

export default Success