import React, { useEffect, useState } from 'react'
import Navbar from './components/Navbar.jsx';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import { DateTimeManager } from '../common/DateTimeManager.js';
import { countTestDrives, deleteLead, deleteMotorcycle, deleteTestDrive, getAllExhibitors, getLeads, getMotorcycleAvailability, getMotorcycles, getTestDrives } from '../common/ApiService.js';
import UseStateContext from './session/UseStateContext.jsx';
import { useNavigate, useParams } from 'react-router-dom';
import { api, getOrders, getTicketById } from 'ticketino-api-client';
import Loading from './components/Loading.jsx';

const Account = () => {

    const { context: { exhibitor, selected }, setContext } = UseStateContext();

    const [countOfOrders, setCountOfOrders] = useState(0);
    const [orders, setOrders] = useState([]);

    const [exhibitors, setExhibitors] = useState([]);
    const [leads, setLeads] = useState([]);
    const [motorcycles, setMotorcycles] = useState([]);
    const [testDrives, setTestDrives] = useState([]);

    const [loadedOrders, setLoadedOrders] = useState([]);
    const [loadedExhibitors, setLoadedExhibitors] = useState([]);
    const [loadedLeads, setLoadedLeads] = useState([]);
    const [loadedMotorcycles, setLoadedMotorcycles] = useState([]);
    const [loadedTestDrives, setLoadedTestDrives] = useState([]);

    const [selectedTab, setSelectedTab] = useState(selected ?? 3);
    const [numberToSkip, setNumberToSkip] = useState(0);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    let navigate = useNavigate();

    const [filter, setFilter] = useState({
        textExhibitor: "",
        textLead: "",
        textMotorcycle: "",
        textOrder: "",
        status: 0,
        exhibitorId: 0,
        motorcycleId: 0,
        leadId: 0,
        driving: 0,
    })

    const [fromDate, setFromDate] = useState(() => {
        const newDate = new Date();
        newDate.setDate(newDate.getDate() - 200);
        return newDate;
    });

    const [toDate, setToDate] = useState(() => {
        const newDate = new Date()
        newDate.setDate(newDate.getDate() + 200);
        return newDate;
    });

    let baseUrl = process.env.REACT_APP_BASEURL_API;
    let organizerId = process.env.REACT_APP_ORGANIZER_ID;
    let eventId = process.env.REACT_APP_EVENT_ID;

    useEffect(() => {
        if (organizerId > 0 && eventId > 0 && (selectedTab == 3 || selectedTab == 2)) {
            loadToken();
        }
    }, [organizerId, eventId, selectedTab]);

    useEffect(() => {
        if (selectedTab == 3) {
            setLoading(true);
            const timer = setTimeout(() => {
                loadOrders();
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [filter, selectedTab])

    useEffect(() => {
        const dateObject = new Date(fromDate);
        var isDate = !isNaN(dateObject) && dateObject instanceof Date;

        if (isDate) {
            if (selectedTab == 1 || selectedTab == 2 || selectedTab == 4) {
                loadMotorcycles();
                loadLeads();
                loadTestDrives();
            }
        }
    }, [fromDate, toDate, selectedTab])

    useEffect(() => {
        if (selectedTab == 0 || selectedTab == 1) {
            if (exhibitor.isAdmin) {
                loadExhibitors();
            }
        }
    }, [selectedTab])

    useEffect(() => {
        onChangeFilter();
    }, [filter, loadedOrders])

    const updateToken = async () => {
        await axios.get("Ticketino/Token").then(async (res) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;
            api.defaults.headers.common['Authorization'] = "Bearer " + res.data;
        }).catch(() => {
            setError(true);
        })
    }

    const loadToken = async () => {
        await axios.get("Ticketino/Token").then(async (res) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;
            api.defaults.headers.common['Authorization'] = "Bearer " + res.data;

            const count = await countOrders(eventId);
            setCountOfOrders(count);
        }).catch(() => {
            setError(true);
        })
    }

    const loadExhibitors = async () => {
        try {
            setLoading(true);

            const updatedExhibitors = await getAllExhibitors();
            setExhibitors(updatedExhibitors);
            setLoadedExhibitors(updatedExhibitors);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    }

    const loadLeads = async () => {
        try {
            setLoading(true);

            const updatedLeads = await getLeads();

            const leads = await Promise.all(updatedLeads.map(async (lead) => {
                try {
                    await updateToken();
                    const ticket = await getTicketById(lead.ticketId);
                    const numberOfTestDrives = await countTestDrives(lead.id);

                    let currentYear = new Date(2023, 1, 1);

                    if (lead.checkInDate) {
                        let checkInDate = new Date(lead.checkInDate);
                        if (checkInDate <= currentYear) {
                            lead.checkInDate = null;
                        }
                    }

                    if (lead.checkOutDate) {
                        let checkOutDate = new Date(lead.checkOutDate);
                        if (checkOutDate <= currentYear) {
                            lead.checkOutDate = null;
                        }
                    }

                    return { ...lead, address: { ...ticket }, numberOfTestDrives: numberOfTestDrives ?? 0 };
                } catch (error) {
                    return { ...lead, address: {} };
                }
            }))

            setLeads(leads);
            setLoadedLeads(leads);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    }

    const loadMotorcycles = async () => {
        try {
            setLoading(true);

            let currentFromDate = new Date();
            let currentToDate = new Date();

            currentToDate.setMinutes(currentToDate.getMinutes() + 1);

            let queryFromDate = new DateTimeManager(currentFromDate).getDefaultDateTimeFormat()
            let queryToDate = new DateTimeManager(currentToDate).getDefaultDateTimeFormat()

            let updatedMotorcycles = await getMotorcycles();

            updatedMotorcycles = await Promise.all(updatedMotorcycles.map(async (motorcycle) => {
                const availability = await getMotorcycleAvailability(motorcycle.id, queryFromDate, queryToDate);
                return { ...motorcycle, availability: availability }
            }))

            setMotorcycles(updatedMotorcycles);

            setMotorcycles(updatedMotorcycles);
            setLoadedMotorcycles(updatedMotorcycles);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    }

    const loadTestDrives = async () => {
        try {
            setLoading(true);

            const updatedTestDrives = await getTestDrives();
            setTestDrives(updatedTestDrives);
            setLoadedTestDrives(updatedTestDrives);

            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    }

    const countOrders = async (eventId) => {
        try {
            const response = await axios.get(`${baseUrl}/Orders/Count?eventId=${eventId}&includeCancelledOrders=false`);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    const getTicketsOfOrder = async (orderId) => {
        try {
            const response = await axios.get(`${baseUrl}/Order/${orderId}/Tickets`);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    const loadOrders = async () => {
        try {
            setLoading(true);

            const updatedOrders = await getOrders({ eventId: eventId, includeCancelledOrders: false, numberToSkip: numberToSkip, filterText: filter.textOrder });

            let orders = await Promise.all(updatedOrders.map(async (order) => {
                try {
                    const tickets = await getTicketsOfOrder(order.id);

                    return { ...order, address: { ...tickets[0] }, uniqueKey: tickets[0]?.uniqueKey };
                } catch (error) {
                    return { ...order, address: {} };
                }
            }))

            orders.sort((a, b) => {
                const fullNameA = `${a.address?.lastname} ${a.address?.firstname}`.toUpperCase();
                const fullNameB = `${b.address?.lastname} ${b.address?.firstname}`.toUpperCase();
                if (fullNameA < fullNameB) {
                    return -1;
                }
                if (fullNameA > fullNameB) {
                    return 1;
                }
                return 0;
            });

            if (exhibitor?.isAdmin) {
                setOrders(orders);
            }

            setLoadedOrders(orders);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    }

    const downloadLeads = async () => {
        const { motorcycleId, textLead } = filter;

        let queryFromDate = new DateTimeManager(fromDate).getDefaultDateFormat()
        let queryToDate = new DateTimeManager(toDate).getDefaultDateFormat()

        var url = `/Leads/${exhibitor.id}/Export?fromDate=${queryFromDate}&toDate=${queryToDate}`;

        if (filter.motorcycleId > 0) {
            url += `&motorcycleId=${filter.motorcycleId}`;
        }

        await axios
            .get(url, { responseType: "blob", })
            .then((res) => {
                const data = window.URL?.createObjectURL(res.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = "Testfahrer_Export.xlsx";
                link.click();
            })
            .catch((error) => console.error(error));
    }

    const downloadMotorcycles = async () => {
        const { status, exhibitorId, textMotorcycle } = filter;

        let queryFromDate = new DateTimeManager(fromDate).getDefaultDateFormat()
        let queryToDate = new DateTimeManager(toDate).getDefaultDateFormat()

        var url = `/Motorcycles/${exhibitor.id}/Export?fromDate=${queryFromDate}&toDate=${queryToDate}`;

        if (status > 0) {
            url += `&status=${filter.status}`;
        }

        if (exhibitorId > 0) {
            url += `&filterExhibitorId=${filter.exhibitorId}`;
        }

        if (textMotorcycle != "") {
            url += `&filterText=${filter.textMotorcycle}`;
        }

        await axios
            .get(url, { responseType: "blob", })
            .then((res) => {
                const data = window.URL?.createObjectURL(res.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = "Mototraeder_Export.xlsx";
                link.click();
            })
            .catch((error) => console.error(error));
    }

    const downloadTestDrives = async () => {
        const { motorcycleId, leadId } = filter;

        let queryFromDate = new DateTimeManager(fromDate).getDefaultDateFormat()
        let queryToDate = new DateTimeManager(toDate).getDefaultDateFormat()

        var url = `/TestDrives/Export?fromDate=${queryFromDate}&toDate=${queryToDate}`;

        if (motorcycleId > 0) {
            url += `&filterMotorcycleId=${motorcycleId}`
        }

        if (leadId > 0) {
            url += `&filterLeadId=${leadId}`
        }

        await axios
            .get(url, { responseType: "blob", })
            .then((res) => {
                const data = window.URL?.createObjectURL(res.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = "Testfahrten_Export.xlsx";
                link.click();
            })
            .catch((error) => console.error(error));
    }

    const onChangeFilter = () => {

        const { textExhibitor, textMotorcycle, textLead, textOrder, exhibitorId, motorcycleId, status, driving, leadId } = filter;

        let value =
            selectedTab == 0 ? textExhibitor :
                selectedTab == 1 ? textMotorcycle :
                    selectedTab == 2 ? textLead :
                        selectedTab == 3 ? textOrder : ""

        if (selectedTab == 0 && loadedExhibitors?.length > 0) {
            setExhibitors(() => {
                let exhibitors = [...loadedExhibitors];

                if (value != "") {
                    exhibitors = loadedExhibitors?.filter((exhibitor) =>
                        exhibitor?.id?.toString().toLowerCase()?.includes(value) ||
                        exhibitor?.username?.toLowerCase()?.includes(value) ||
                        exhibitor?.standNumber?.toLowerCase()?.includes(value))
                }

                return exhibitors
            })
        }

        if (selectedTab == 1 && loadedMotorcycles?.length > 0) {
            setMotorcycles(() => {
                let motorcycles = [...loadedMotorcycles];

                if (value != "") {
                    motorcycles = loadedMotorcycles?.filter((motorcycle) =>
                        motorcycle?.id?.toString().toLowerCase()?.includes(value) ||
                        motorcycle?.marke?.toLowerCase()?.includes(value) ||
                        motorcycle?.model?.toLowerCase()?.includes(value) ||
                        motorcycle?.handler?.toLowerCase()?.includes(value) ||
                        motorcycle?.internalNumber?.toLowerCase()?.includes(value) ||
                        motorcycle?.licensePlate?.toLowerCase()?.includes(value) ||
                        motorcycle?.kiloMeterStart?.toLowerCase()?.includes(value));
                }

                if (exhibitorId > 0) {
                    motorcycles = motorcycles.filter((motorcycle) => motorcycle?.exhibitorId?.toString().toLowerCase()?.includes(exhibitorId))
                }

                if (status > 0) {
                    motorcycles = motorcycles.filter((motorcycle) => (status == 2 && motorcycle?.availability == false)
                        || (status == 1 && motorcycle?.availability == true) && motorcycle?.status?.toString().toLowerCase()?.includes(status));
                }

                return motorcycles
            })
        }


        if (selectedTab == 2 && loadedLeads?.length > 0) {
            setLeads(() => {
                let leads = [...loadedLeads];

                if (value != "") {
                    leads = loadedLeads?.filter((lead) =>
                        lead?.id?.toString().toLowerCase()?.includes(value) ||
                        lead?.orderId?.toString().toLowerCase()?.includes(value) ||
                        lead?.address?.lastname.toLowerCase()?.includes(value) ||
                        lead?.address?.firstname.toLowerCase()?.includes(value) ||
                        lead?.address?.email.toLowerCase()?.includes(value))
                }

                if (motorcycleId > 0) {
                    leads = leads.filter((lead) => lead?.motorcycleId?.toString().toLowerCase()?.includes(motorcycleId))
                }

                return leads
            })
        }

        if (selectedTab == 3 && loadedOrders?.length > 0) {
            setOrders(() => {
                let orders = [];
                if (textOrder != "") {
                    orders = [...loadedOrders];
                }

                if (textOrder == "" && exhibitor.isAdmin) {
                    orders = [...loadedOrders];
                }

                return orders;
            })
        }

        if (selectedTab == 4 && loadedTestDrives?.length > 0) {
            setTestDrives(() => {
                let testDrives = [...loadedTestDrives];

                if (leadId > 0) {
                    testDrives = testDrives.filter((testDrive) => testDrive.leadId == leadId)
                }

                if (motorcycleId > 0) {
                    testDrives = testDrives.filter((testDrive) => testDrive.motorcycleId == motorcycleId)
                }

                if (driving == 1) {
                    let currentDate = new Date();
                    testDrives = testDrives.filter((testDrive) => {
                        let checkInDate = testDrive.checkInDate ? new Date(testDrive.checkInDate) : null;
                        let checkOutDate = testDrive.checkOutDate ? new Date(testDrive.checkOutDate) : null;

                        let isDriving = false;

                        if (checkOutDate) {
                            isDriving = currentDate >= checkInDate && currentDate <= checkOutDate;
                        } else {
                            isDriving = currentDate >= checkInDate;
                        }

                        return isDriving;
                    });
                }

                return testDrives
            })
        }
    }

    return (
        <>
            <Navbar />
            {/* <Banner /> */}
            <div className='page-container container mt-4'>
                <nav className="nav nav-pills nav-fill mb-5">
                    {exhibitor.isAdmin && <button className={"nav-link " + (selectedTab == 0 ? "active" : "")} onClick={() => setSelectedTab(0)}>Aussteller</button>}
                    <button className={"nav-link " + (selectedTab == 1 ? "active" : "")} onClick={() => setSelectedTab(1)}>Motorräder</button>
                    <button className={"nav-link " + (selectedTab == 2 ? "active" : "")} onClick={() => setSelectedTab(2)}>Testfahrer</button>
                    <button className={"nav-link " + (selectedTab == 3 ? "active" : "")} onClick={() => setSelectedTab(3)}>Registrationen</button>
                    <button className={"nav-link " + (selectedTab == 4 ? "active" : "")} onClick={() => setSelectedTab(4)}>Testfahrten</button>
                </nav>
                {(selectedTab == 0 && exhibitor.isAdmin) &&
                    (loading ? <Loading /> : <div className='mb-4'>
                        <div className="row">
                            <div className="col-4">
                                <h1 className='text text-dark fw-semibold fs-3 p-1'>Aussteller</h1>
                            </div>
                            <div className="col-8 text-end">
                                <button
                                    className='btn btn-danger'
                                    onClick={() => {
                                        setContext({ selected: 0 })
                                        navigate("/exhibitor")
                                    }}
                                >
                                    Aussteller erfassen
                                    <span><i className="bi bi-plus-circle text-light ms-2"></i></span>
                                </button>
                            </div>
                        </div>
                        <input className='form-control mb-2' placeholder='Suchen' value={filter.textExhibitor} onChange={(e) => setFilter({ ...filter, textExhibitor: e.target.value })} />
                        {exhibitors?.length > 0 && <div className='table-responsive'>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Benutzername</th>
                                        <th scope="col">Interne Nummer</th>
                                        <th scope="col">Admin</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        exhibitors?.map((e, index) => (
                                            <tr key={index}>
                                                <td className='col-4'>{e?.username}</td>
                                                <td>{e?.standNumber}</td>
                                                <td>{e?.isAdmin ? "Ja" : "Nein"}</td>
                                                <td><i
                                                    onClick={() => {
                                                        setContext({ selected: 0 })
                                                        navigate(`/exhibitor/${e?.id}`)
                                                    }}
                                                    className="bi bi-pencil"></i></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>}
                    </div>)}
                {/* <div className='m-3'>
                    <div className='row m-0-p-0'>
                        <div className="col-6 m-0-p-0 text-end">
                            <DatePicker
                                dateFormat="dd.MM.yy"
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                                className="form-control datepicker-input text-center text-secondary"
                                id="exampleFormControlInput1"
                                selected={fromDate}
                                onChange={(date) => { setFromDate(date) }}
                                autoComplete='off'
                            />
                        </div>
                        <div className="col-6 m-0-p-0 text-center">
                            <DatePicker
                                dateFormat="dd.MM.yy"
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                                className="form-control datepicker-input text-center text-secondary"
                                id="exampleFormControlInput1"
                                selected={toDate}
                                onChange={(date) => { setToDate(date) }}
                                autoComplete='off'
                            />
                        </div>
                    </div>
                </div> */}
                {(selectedTab == 1) &&
                    (loading ? <Loading /> : <div className='mb-5'>
                        <div className="row">
                            <div className="col-4">
                                <h1 className='text text-dark fw-semibold fs-3 p-1'>Motorräder</h1>
                            </div>
                            <div className="col-8 text-end">
                                <button
                                    className='btn btn-danger'
                                    onClick={() => {
                                        setContext({ selected: 1 })
                                        navigate("/motorcycle")
                                    }}
                                >
                                    Motorrad erfassen
                                    <span><i className="bi bi-plus-circle text-light ms-2"></i></span>
                                </button>
                            </div>
                        </div>
                        <input className='form-control mb-2' placeholder='Suchen' value={filter.textMotorcycle} onChange={(e) => setFilter({ ...filter, textMotorcycle: e.target.value })} />
                        <div className="row">
                            <div className="col-6">
                                <div className="form-floating mb-3">
                                    <select className="form-select" id="floatingSelect" name='status' value={filter.status} onChange={(e) => setFilter({ ...filter, status: e.target.value })}>
                                        <option defaultValue={0} value={0}>Alle</option>
                                        <option value={1}>Frei</option>
                                        <option value={2}>Besetzt</option>
                                        <option value={3}>Defekt</option>
                                    </select>
                                    <label htmlFor="floatingSelect">Status</label>
                                </div>
                            </div>
                            {exhibitor.isAdmin && <div className="col-6">
                                <div className="form-floating mb-3">
                                    <select className="form-select" id="floatingSelect" name='exhibitorId' value={filter.exhibitorId} onChange={(e) => setFilter({ ...filter, exhibitorId: e.target.value })}>
                                        <option value={0}>Alle</option>
                                        {exhibitors?.map((exhibitor, index) => (
                                            <option key={index} value={exhibitor.id}>{exhibitor.username}</option>
                                        ))}
                                    </select>
                                    <label htmlFor="floatingSelect">Aussteller</label>
                                </div>
                            </div>}
                        </div>
                        {motorcycles?.length > 0 && <div className='table-responsive'>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Marke</th>
                                        <th scope="col">Modell</th>
                                        <th scope="col">Interne Nummer</th>
                                        <th scope="col">Status</th>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        motorcycles?.map((mc, index) => (
                                            <tr key={index}>
                                                <td>{mc?.marke}</td>
                                                <td>{mc?.model}</td>
                                                <td>{mc?.internalNumber}</td>
                                                <td><i className={`bi bi-circle-fill text-${mc.availability ? "success" : "danger"}`}></i></td>
                                                <td><i
                                                    onClick={() => {
                                                        setContext({ selected: 1 })
                                                        navigate(`/motorcycle/${mc?.id}`)
                                                    }}
                                                    className="bi bi-pencil"></i></td>
                                                <td><i
                                                    onClick={async () => {
                                                        await deleteMotorcycle(mc?.id);
                                                        await loadMotorcycles();
                                                    }}
                                                    className="bi bi-x-circle"></i></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                            <div className='container text-center p-0'>
                                <button className='success-button' onClick={downloadMotorcycles}>Herunterladen</button>
                            </div>
                        </div>}
                    </div>)}
                {(selectedTab == 2) &&
                    (loading ? <Loading /> : <div className='mb-4'>
                        <h1 className='text text-dark fw-semibold fs-3 p-1'>Testfahrer</h1>
                        <input className='form-control mb-2' placeholder='Suchen' value={filter.textLead} onChange={(e) => setFilter({ ...filter, textLead: e.target.value })} />
                        {leads?.length > 0 && <div className='table-responsive'>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" className='hide-mobile'>Anzahl Fahrten</th>
                                        <th scope="col" className='hide-mobile'>Ticket Code</th>
                                        <th scope="col">Name</th>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        leads?.map((l, index) => (
                                            <tr key={index}>
                                                <td className='hide-mobile'>{l?.numberOfTestDrives}</td>
                                                <td className='hide-mobile'>{l?.address?.uniqueKey}</td>
                                                <td>{l?.address?.firstname + " " + l?.address?.lastname}</td>
                                                <td><i
                                                    onClick={() => {
                                                        setContext({ selected: 2 })
                                                        navigate(`/lead/${l?.id}`)
                                                    }}
                                                    className="bi bi-pencil"></i></td>
                                                <td><i
                                                    onClick={async () => {
                                                        await deleteLead(l?.id);
                                                        await loadLeads();
                                                    }}
                                                    className="bi bi-x-circle"></i></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                            <div className='container text-center p-0'>
                                <button className='success-button' onClick={downloadLeads}>Herunterladen</button>
                            </div>
                        </div>}
                    </div>)}
                {(selectedTab == 3) &&
                    <div className='mb-4'>
                        <h1 className='text text-dark fw-semibold fs-3 p-1'>Registrationen</h1>
                        <input className='form-control mb-2' placeholder='Suchen' value={filter.textOrder} onChange={(e) => setFilter({ ...filter, textOrder: e.target.value })} />
                        {loading ? <Loading /> : <>
                            {(orders.length > 0) && <div className='table-responsive'>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Ticket Code</th>
                                            <th scope="col">E-Mail</th>
                                            <th scope="col">Name</th>
                                            <th scope="col"></th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            orders.map((o, index) => (
                                                <tr key={index}>
                                                    <td>{o.uniqueKey}</td>
                                                    <td>{o.address?.email}</td>
                                                    <td>{o.address?.lastname} {o.address?.firstname}</td>
                                                    <td><i
                                                        onClick={() => {
                                                            setContext({ selected: 3 })
                                                            navigate(`/guest/${o.id}`)
                                                        }}
                                                        className="bi bi-pencil"></i></td>
                                                    <td><i
                                                        onClick={() => {
                                                            setContext({ selected: 3 })
                                                            navigate(`/contact/${o.uniqueKey}`)
                                                        }}
                                                        className="bi bi-qr-code-scan"></i></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>}
                        </>}
                    </div>
                }
                {(selectedTab == 4) &&
                    (loading ? <Loading /> : <div className='mb-4'>
                        <h1 className='text text-dark fw-semibold fs-3 p-1'>Testfahrten</h1>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-floating mb-3">
                                    <select className="form-select" id="floatingSelect" name='motorcycleId' value={filter.motorcycleId} onChange={(e) => setFilter({ ...filter, motorcycleId: e.target.value })}>
                                        <option value={0}>Alle</option>
                                        {motorcycles.map((motorcycle, index) => (
                                            <option key={index} value={motorcycle.id}>{motorcycle.marke} {motorcycle.model} - {motorcycle.licensePlate}</option>
                                        ))}
                                    </select>
                                    <label htmlFor="floatingSelect">Motorräder</label>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-floating mb-3">
                                    <select className="form-select" id="floatingSelect" name='leadId' value={filter.leadId} onChange={(e) => setFilter({ ...filter, leadId: e.target.value })}>
                                        <option value={0}>Alle</option>
                                        {leads?.map((lead, index) => (
                                            <option key={index} value={lead.id}>{lead?.ticketId} - {lead?.address?.firstname} {lead?.address?.lastname}</option>
                                        ))}
                                    </select>
                                    <label htmlFor="floatingSelect">Testfahrer</label>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-floating mb-3">
                                    <select className="form-select" id="floatingSelect" name='driving' value={filter.driving} onChange={(e) => setFilter({ ...filter, driving: e.target.value })}>
                                        <option value={0}>Alle</option>
                                        <option value={1}>Ja</option>
                                    </select>
                                    <label htmlFor="floatingSelect">auf Testfahrt</label>
                                </div>
                            </div>
                        </div>
                        {(testDrives?.length > 0) && <div className='table-responsive'>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Datum / Uhrzeit Check-in</th>
                                        <th scope="col">Datum / Uhrzeit Check-out</th>
                                        <th scope="col">Testfahrer</th>
                                        <th scope="col">Motorrad</th>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        testDrives.map((td, index) => (
                                            <tr key={index}>
                                                <td>{td?.checkInDate && new Date(td?.checkInDate).toLocaleDateString("de-DE")} {td?.checkInDate && new Date(td?.checkInDate).toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit', hour12: false })}</td>
                                                <td>{td?.checkOutDate && new Date(td?.checkOutDate).toLocaleDateString("de-DE")} {td?.checkOutDate && new Date(td?.checkOutDate).toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit', hour12: false })}</td>
                                                <td>{leads?.find(l => l.id == td?.leadId)?.address?.lastname} {leads?.find(l => l.id == td?.leadId)?.address?.firstname}</td>
                                                <td>{motorcycles?.find(m => m?.id == td?.motorcycleId)?.marke} {motorcycles?.find(m => m?.id == td?.motorcycleId)?.model}</td>
                                                <td><i
                                                    onClick={() => {
                                                        setContext({ selected: 4 })
                                                        navigate(`/testdrive/${td.id}`)
                                                    }}
                                                    className="bi bi-pencil"></i></td>
                                                <td><i
                                                    onClick={async () => {
                                                        await deleteTestDrive(td?.id);
                                                        await loadTestDrives();
                                                    }}
                                                    className="bi bi-x-circle"></i></td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                            <div className='container text-center p-0'>
                                <button className='success-button' onClick={downloadTestDrives}>Herunterladen</button>
                            </div>
                        </div>}
                    </div>
                    )
                }
            </div>
        </>
    )
}

export default Account