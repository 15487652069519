import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { api, getTicketTypeById, getTicketTypeInfosByTicketTypeId } from 'ticketino-api-client';
import axios from 'axios';

import "react-datepicker/dist/react-datepicker.css";
import errorImg from "../images/error-o.svg"

import Navbar from './components/Navbar';
import Banner from './components/Banner';
import Loading from './components/Loading';
import UseStateContext from './session/UseStateContext';

import { checkInLead, countTestDrives, createLead, createTestDrive, getExhibitorById, getLeadByTicketId, getMotorcycleAvailability, getMotorcycleById, getMotorcycleSchedule, getMotorcycles, getTestDrives, updateLead, updateTestDrive } from '../common/ApiService';
import Success from './components/Success.jsx';

const Contact = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [submitError, setSubmitError] = useState("");
    const [errorResponse, setErrorResponse] = useState("");

    const [isUpdate, setIsUpdate] = useState(false);

    const [motorcycles, setMotorcycles] = useState([]);
    const [selectedMotorcycle, setSelectedMotorcycle] = useState({});

    const [existingSchedule, setExistingSchedule] = useState([]);

    const { context: { scanner, exhibitor }, setContext } = UseStateContext();

    const defaultLead = {
        id: 0,
        exhibitorId: 0,
        remarks: "",
        ticketId: 0,
        ticketName: "",
        orderId: 0,
        checkIns: 0
    }

    const [lead, setLead] = useState({ ...defaultLead });

    const defaultTestDrive = {
        id: 0,
        leadId: lead?.id ?? 0,
        exhibitorId: exhibitor?.id ?? 0,
        motorcycleId: 0,
        scanner: scanner ?? "",
        name: "",
        remarks: "",
        checkInDate: null,
        checkOutDate: null
    }

    const [testDrive, setTestDrive] = useState({ ...defaultTestDrive });

    let baseUrl = process.env.REACT_APP_BASEURL_API;
    let eventId = process.env.REACT_APP_EVENT_ID;

    const { uniqueKey } = useParams();

    let navigate = useNavigate();

    useEffect(() => {
        if (!(uniqueKey > 0)) {
            setError(true);
        }
    }, [uniqueKey]);

    useEffect(() => {
        setContext({ uniqueKey: uniqueKey });
        loadToken()
    }, [uniqueKey]);

    const loadToken = async () => {
        await axios.get("Ticketino/Token").then((res) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;
            api.defaults.headers.common['Authorization'] = "Bearer " + res.data;

            if (uniqueKey > 0) {
                loadTicket(uniqueKey);
                loadMotorcycles();
            }
        }).catch(() => {
            setError(true);
            setLoading(false);
        })
    }

    const loadTicket = async (uniqueKey) => {
        try {
            setLoading(true);
            setError(false);

            let isError = false;
            let errorResponse = "";

            const ticket = await requestTicketByUniqueKey(uniqueKey);

            let existingLead = await getLeadByTicketId(ticket.id);
            let existingTestDrive = { ...testDrive };

            let isAlreadyScanned = false;

            if (existingLead) {
                isAlreadyScanned = true;
            }

            if (!isAlreadyScanned) {
                const ticketType = await getTicketTypeById(ticket.ticketTypeId);

                if (ticketType?.eventId != eventId) {
                    isError = true;
                    errorResponse = "Das folgende Ticket gehört zu einem anderen Event. Bitte versuchen Sie es erneut."
                } else if (ticketType?.eventId == eventId) {
                    const ticketTypeInfo = await getTicketTypeInfosByTicketTypeId(ticket.ticketTypeId);
                    const ticketTypeName = ticketTypeInfo.find(tti => tti.languageId == 0)?.name;

                    let updatedLead = {
                        ...lead,
                        exhibitorId: exhibitor.id,
                        scanner: scanner,
                        ticketId: ticket.id,
                        ticketName: ticketTypeName,
                        orderId: ticket.orderId,
                        firstName: ticket.firstname,
                        name: ticket.lastname,
                        email: ticket.email
                    }

                    existingLead = await createLead(updatedLead);
                }
            }

            existingTestDrive = { ...existingTestDrive, leadId: existingLead?.id, exhibitorId: exhibitor.id, scanner: scanner };

            if (ticket?.isCancelled) {
                isError = true;
                errorResponse = "Das folgende Ticket wurde storniert. Bitte versuchen Sie es erneut.";
            }

            let testDrives = await getTestDrives(existingLead.id);

            let numberOfTestDrives = await countTestDrives(existingLead.id);

            let checkInDate = null;
            let checkOutDate = null;

            let testDrivesOfExhibitor = testDrives.filter(td => td.exhibitorId == exhibitor?.id);
            let testDrivesOfOtherExhibitor = testDrives.filter(td => td.exhibitorId != exhibitor?.id);

            let currentDate = new Date();

            let otherExhibitor = {};

            if (!exhibitor?.isAdmin) {
                // check if test drives from other exhibitors exist whose test drives have not been completed.
                await Promise.all(
                    testDrivesOfOtherExhibitor?.map(async (tooe) => {
                        let tooeCheckOutDate = tooe.checkOutDate ? new Date(tooe.checkOutDate) : null;

                        if (tooeCheckOutDate == null || (tooeCheckOutDate && (tooeCheckOutDate > currentDate))) {
                            otherExhibitor = await getExhibitorById(tooe.exhibitorId);
                            isError = true;
                            errorResponse = `Die Testfahrt mit ${otherExhibitor?.name} muss zuerst beendet werden, bevor eine neue Testfahrt gestartet werden kann.`;
                        }
                    })
                )
            }

            if (testDrivesOfExhibitor.length > 0 && !isError) {
                // fetch the last created test drive
                let selectedTestDrive = testDrivesOfExhibitor[testDrivesOfExhibitor.length - 1];

                checkInDate = selectedTestDrive.checkInDate ? new Date(selectedTestDrive.checkInDate) : null;
                checkOutDate = selectedTestDrive.checkOutDate ? new Date(selectedTestDrive.checkOutDate) : null;

                // only check if checkOut has been defined once before.
                // create a new testfahrt in the case that the existing testfahrt is already completed
                if (checkOutDate == null || (checkOutDate && (checkOutDate > currentDate))) {
                    selectedTestDrive.checkInDate = checkInDate ?? null;
                    selectedTestDrive.checkOutDate = checkOutDate ?? null;

                    if (selectedTestDrive.motorcycleId > 0) {
                        await loadMotorcycle(selectedTestDrive.motorcycleId);
                    }

                    existingTestDrive = { ...testDrive, ...selectedTestDrive };
                    setIsUpdate(true);
                }
            }

            if (numberOfTestDrives >= 6 && (existingTestDrive.checkInDate == null && existingTestDrive.checkOutDate == null)) {
                isError = true;
                errorResponse = "Das Ticket wurde bereits 6 Mal gescannt. Bitte versuchen Sie es mit einem anderen Ticket erneut.";
            }

            setTestDrive({ ...existingTestDrive });
            setLead({ ...existingLead, numberOfTestDrives: numberOfTestDrives, firstName: ticket.firstname, name: ticket.lastname, email: ticket.email })

            setError(isError);
            setErrorResponse(errorResponse)
            setLoading(false);
        }
        catch (error) {
            setError(true);
            setLoading(false);
            console.error(error);
        }
    }

    const loadMotorcycle = async (motorcycleId) => {
        try {
            const updatedMotorcycle = await getMotorcycleById(motorcycleId);
            const motorcycleSchedule = await getMotorcycleSchedule(motorcycleId);

            setSelectedMotorcycle(updatedMotorcycle);
            setExistingSchedule(motorcycleSchedule);
        } catch (error) {
            console.error(error);
        }
    }

    const loadMotorcycles = async (id) => {
        let updatedMotorcycles = await getMotorcycles();

        updatedMotorcycles = await Promise.all(updatedMotorcycles.map(async (motorcycle) => {
            const availability = await getMotorcycleAvailability(motorcycle.id);
            return { ...motorcycle, availability: availability }
        }))

        setMotorcycles(updatedMotorcycles);
    }

    const requestTicketByUniqueKey = async (uniqueKey) => {
        try {
            const res = await axios.get(`${baseUrl}/Ticket?uniqueKey=${uniqueKey}`);
            return res.data;
        } catch (error) {
            console.error(error);
        }
    }

    const validateTestDrive = () => {
        if (testDrive.id > 0 && testDrive.checkOutDate == null) {
            setSubmitError("Bitte füllen Sie die Checkout-Zeit aus und versuchen Sie es erneut.")
            var element = document.getElementById("testdriveCheckout");
            if (element) {
                element.style.border = "1px solid red";
            }
            return false;
        } else {
            return true;
        }
    }

    const onSubmit = async () => {

        var isTestDriveValid = validateTestDrive();

        if (isTestDriveValid) {
            const checkIns = await checkInLead(lead.id);

            if (checkIns > 0) {
                const response = testDrive.id == 0 ?
                    await createTestDrive({ ...testDrive })
                    : await updateTestDrive({ ...testDrive });
                if (response?.id > 0) {
                    setContext({ lead: lead, editMode: !(testDrive.id == 0) });
                    navigate("/success");
                } else {
                    setSubmitError(response)
                }
            }
        }
    }

    const isDateDisabled = (date) => {
        return existingSchedule?.filter(es => es.testDriveId != testDrive?.id).some(({ checkIn, checkOut }) => {
            const checkinDate = new Date(checkIn);
            const checkoutDate = checkOut ? new Date(checkOut) : null;

            if (checkoutDate == null) {
                return date >= checkinDate
            } else {
                return (date >= checkinDate && date <= checkoutDate);
            }
        })
    }

    return (
        <>
            <Navbar />
            <Banner />
            {loading ? <Loading /> : <div className='page-container container mt-4 mb-5'>
                {(error || uniqueKey <= 0) ? <div className='content m-3 mb-4'>
                    <div className='row'>
                        <div className='col-2 success-contact'><img src={errorImg}></img></div>
                        <div className='col-10 p-0 ps-2 success-content'>
                            <h1 className='text text-dark fw-bold fs-5 mb-1'>Scan war nicht erfolgreich</h1>
                            <p className='text text-secondary fs-6 mb-0'>{errorResponse}</p>
                            <p></p>
                        </div>
                    </div>
                </div> : <Success lead={lead} testDrive={testDrive} />}
                <>
                    {!isUpdate ? <h1 className='text text-dark fw-semibold fs-3 m-3'>Testfahrt erfassen</h1> : <h1 className='text text-dark fw-semibold fs-3 m-3'>Testfahrt bearbeiten</h1>}
                    {(isUpdate && lead.numberOfTestDrives < 6) && <div className='fs-6 m-3 navbar-standnumber'
                        onClick={() => {
                            setTestDrive({ ...testDrive, ...defaultTestDrive })
                            setSelectedMotorcycle({})
                        }}
                    ><i className="bi bi-plus-square"></i> Eine neue Testfahrt erstellen</div>}
                    <div className='content m-3'>
                        <>
                            <div className='mb-3'>
                                <label htmlFor="exampleFormControlInput1" className="form-label text text-secondary fs-6 mb-3'">Motorrad auswählen<span className='text text-danger'>*</span></label><br></br>
                                <select className="form-select"
                                    onChange={(e) => {
                                        setTestDrive({ ...testDrive, motorcycleId: e.target.value })

                                        if (e.target.value > 0) {
                                            loadMotorcycle(e.target.value);
                                        } else {
                                            setSelectedMotorcycle({});
                                            setExistingSchedule([]);
                                        }
                                    }}
                                    value={testDrive.motorcycleId}>
                                    <option value={0}></option>
                                    {motorcycles.map((motorcycle, index) => (
                                        <option key={index} disabled={!motorcycle.availability} value={motorcycle.id}>{motorcycle.marke} {motorcycle.model} - {motorcycle.licensePlate}</option>
                                    ))}
                                </select>

                            </div>
                        </>
                        <div className='mb-3'>
                            <p>Status: {selectedMotorcycle?.status == 1 ? "Frei" : selectedMotorcycle?.status == 2 ? "Besetzt" : selectedMotorcycle?.status == 3 ? "Defekt" : ""}</p>
                        </div>
                        <>
                            <div className="form-floating mb-3">
                                <input className="form-control" id="floatingInput" placeholder='Marke' name='marke' value={selectedMotorcycle?.marke ?? ""} readOnly />
                                <label htmlFor="floatingInput">Marke</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input className="form-control" id="floatingInput" placeholder='Model' name='model' value={selectedMotorcycle?.model ?? ""} readOnly />
                                <label htmlFor="floatingInput">Model</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input className="form-control" id="floatingInput" placeholder='Interne Nummer' name='internalNumber' value={selectedMotorcycle?.internalNumber ?? ""} readOnly />
                                <label htmlFor="floatingInput">Interne Nummer</label>
                            </div>
                        </>
                        <>
                            <div className="row">
                                <div className='col-md-6 col-12 mb-3 row'>
                                    <label className="form-label text text-secondary fs-6">
                                        Start Probefahrt (Check-In)<span className='text text-danger'>*</span>
                                    </label>
                                    <div className="col-12 mb-1">
                                        <DatePicker
                                            dateFormat="dd.MM.yy, HH:mm"
                                            className="form-control datepicker-input"
                                            selected={testDrive.checkInDate}
                                            onChange={(date) => setTestDrive({ ...testDrive, checkInDate: date })}
                                            autoComplete='off'
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            disabled={!(selectedMotorcycle?.id > 0)}
                                            filterDate={(date) => !isDateDisabled(date)}
                                            filterTime={(time) => !isDateDisabled(time)}
                                            showTimeSelect />
                                    </div>
                                    <span className='text text-secondary fs-6 ms-2' style={{ textDecoration: "underline" }} onClick={(date) => setTestDrive({ ...testDrive, checkInDate: new Date() })}>Jetzt</span>
                                </div>
                                {testDrive.id > 0 && <div className='col-md-6 col-12 mb-3 row'>
                                    <label className="form-label text text-secondary fs-6">
                                        Ende Probefahrt (Check-Out)<span className='text text-danger'>*</span>
                                    </label>
                                    <div className="col-12 mb-1">
                                        <DatePicker
                                            id="testdriveCheckout"
                                            dateFormat="dd.MM.yy, HH:mm"
                                            className="form-control datepicker-input"
                                            selected={testDrive.checkOutDate}
                                            onChange={(date) => setTestDrive({ ...testDrive, checkOutDate: date })}
                                            autoComplete='off'
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            filterDate={(date) => !isDateDisabled(date)}
                                            filterTime={(time) => !isDateDisabled(time)}
                                            showTimeSelect />
                                    </div>
                                    <span className='text text-secondary fs-6' style={{ textDecoration: "underline" }} onClick={(date) => setTestDrive({ ...testDrive, checkOutDate: new Date() })}>Jetzt</span>
                                </div>}

                            </div>
                        </>
                        <>
                            <div className='mb-3'>
                                <label htmlFor="exampleFormControlInput1" className="form-label text text-secondary fs-6 mb-3'">Bemerkung</label><br></br>
                                <textarea className="form-control" rows="5" name="remarks" value={testDrive.remarks} onChange={(e) => setTestDrive({ ...testDrive, remarks: e.target.value })}></textarea>
                            </div>
                        </>
                        {submitError != "" && <div className="alert alert-danger" role="alert">
                            {submitError}
                        </div>}
                        <div>
                            <button className='button' disabled={error} onClick={onSubmit}>Bestätigen</button>
                        </div>
                    </div>
                </>
            </div>}
        </>
    )
}

export default Contact