import React from 'react'

const Loading = () => {
    return (
        <div className="d-flex justify-content-center p-5">
            <div className="spinner-border" role="status" style={{
                color: "#e40000",
                top: "50%",
                left: "50%",
                width: "2rem",
                height: "2rem"
            }}>
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    )
}

export default Loading