import React, { createContext, useContext, useEffect, useState } from "react";

export const stateContext = createContext();

const getFreshContext = () => {
  if (localStorage.getItem("context") === null || localStorage.getItem("context") === "")
    localStorage.setItem(
      "context",
      JSON.stringify({
        loggedIn: false,
        exhibitor: {},
        leadId: 0,
        motorcycleId: 0,
        lead: {},
        uniqueKey: 0,
        requestType: "",
        scanner: "",
        selected: 1,
        editMode: false
      })
    );
  return JSON.parse(localStorage.getItem("context"));
};

export default function UseStateContext() {
  const { context, setContext } = useContext(stateContext);
  return {
    context,
    setContext: (obj) => {
      setContext({ ...context, ...obj });
    },
    resetContext: () => {
      localStorage.removeItem("context");
      setContext(getFreshContext());
    },
  };
}

export function ContextProvider({ children }) {
  const [context, setContext] = useState(getFreshContext());

  useEffect(() => {
    localStorage.setItem("context", JSON.stringify(context));
  }, [context]);

  return (
    <stateContext.Provider value={{ context, setContext }}>
      {children}
    </stateContext.Provider>
  );
}